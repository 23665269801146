import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "guide"
    }}>{`Guide`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "connect-wallet"
    }}>{`Connect Wallet`}</h2>
    <p>{`Connect your crypto wallet to the website and go to your dashboard.`}</p>
    <p><img alt="guide" src={require("./public/images/guide-1.png")} /></p>
    <hr></hr>
    <h2 {...{
      "id": "buy-clc-on-uniswap"
    }}>{`Buy CLC on Uniswap`}</h2>
    <p>{`After your wallet is connected, you can buy or swap CLC in the dashboard.`}</p>
    <p><img alt="guide" src={require("./public/images/guide-2.png")} /></p>
    <p>{`Buy button on the dashboard is default to USDC-CLC pair, but you should check address carefully if you didn’t enter Uniswap from the dashboard.`}</p>
    <p><img alt="guide" src={require("./public/images/uniswap.png")} /></p>
    <p>{`You can swap any pair of tokens on Polygon Chain, check the exchange rate by placing one of the currency on the top.`}</p>
    <p>{`Once the transaction is completed, you will see your CLC balance in your wallet and the dashboard`}</p>
    <p><img alt="howtostart" src={require("./public/images/white.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`If your CLC is not topped up, you should wait for a while and refresh your wallet and the dashboard`}</p>
    </blockquote>
    <hr></hr>
    <h2 {...{
      "id": "deposit-clc-in-to-the-game"
    }}>{`Deposit CLC in to the game`}</h2>
    <p>{`You can deposit CLC into the game from the dashboard.`}</p>
    <p><img alt="guide" src={require("./public/images/guide-5.png")} /></p>
    <h2 {...{
      "id": "withdraw-clc-from-the-game"
    }}>{`Withdraw CLC from the game`}</h2>
    <p>{`You can withdraw your CLC from the game to your wallet in the dashboard.`}</p>
    <p><img alt="guide" src={require("./public/images/guide-6.png")} /></p>
    <blockquote>
      <h4 parentName="blockquote" {...{
        "id": "deposit--withdraw-description-details-play-and-earn"
      }}>{`Deposit & Withdraw description details `}<a parentName="h4" {...{
          "href": "https://doc.season.town/play-and-earn"
        }}>{`Play and Earn`}</a></h4>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      